








































































































































import Vue from 'vue'
import Model, { WhereQuery } from '@/lib/prest-query-builder'
import { Component, Watch } from 'vue-property-decorator'
import { User } from '@/interfaces/user'
import InvestmentsChart from '@/components/charts/InvestmentsChart.vue'

const { VUE_APP_API_PREST_NEW, VUE_APP_DASHBOARD_API } = process.env

@Component({
  components: {
    InvestmentsChart
  }
})
export default class InvestmentsTable extends Vue {
  $moment: any

  state = {
    loading: false
  }

  filters = {
    status: {
      field: 'status',
      operator: '$eq',
      value: 'PENDENTE'
    },
    today: {
      min: {
        field: 'criado',
        operator: '$gte',
        value: this.$moment().hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'criado',
        operator: '$lte',
        value: this.$moment().hour(23).minute(59).seconds(59).toISOString()
      }
    },
    yesterday: {
      min: {
        field: 'criado',
        operator: '$gte',
        value: this.$moment().subtract(1, 'days').hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'criado',
        operator: '$lte',
        value: this.$moment().subtract(1, 'days').hour(23).minute(59).seconds(59).toISOString()
      }
    },
    currentWeek: {
      min: {
        field: 'criado',
        operator: '$gte',
        value: this.$moment().weekday(0).hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'criado',
        operator: '$lte',
        value: this.$moment().weekday(6).hour(23).minute(59).seconds(59).toISOString()
      }
    },

    lastWeek: {
      min: {
        field: 'criado',
        operator: '$gte',
        value: this.$moment().weekday(-7).hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'criado',
        operator: '$lte',
        value: this.$moment().weekday(-1).hour(23).minute(59).seconds(59).toISOString()
      }
    },
    currentMonth: {
      min: {
        field: 'criado',
        operator: '$gte',
        value: this.$moment().startOf('month').hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'criado',
        operator: '$lte',
        value: this.$moment().endOf('month').hour(23).minute(59).seconds(59).toISOString()
      }
    },
    lastMonth: {
      min: {
        field: 'criado',
        operator: '$gte',
        value: this.$moment().subtract(1, 'months').startOf('month').hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'criado',
        operator: '$lte',
        value: this.$moment().subtract(1, 'months').endOf('month').hour(23).minute(59).seconds(59).toISOString()
      }
    }
  }

  data = {
    today: {
      pending: 0,
      paid: 0,
      canceled: 0
    },
    yesterday: {
      pending: 0,
      paid: 0,
      canceled: 0
    },
    currentWeek: {
      pending: 0,
      paid: 0,
      canceled: 0
    },
    lastWeek: {
      pending: 0,
      paid: 0,
      canceled: 0
    },
    currentMonth: {
      pending: 0,
      paid: 0,
      canceled: 0
    },
    lastMonth: {
      pending: 0,
      paid: 0,
      canceled: 0
    }
  }

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  get authorizationKey() {
    return this.$store.getters['prest/authorizationKey']
  }

  get dailyPaidIncrease() {
    if (!this.data.yesterday.paid) {
      return (this.data.today.paid - this.data.yesterday.paid) * 100
    } else {
      return ((this.data.today.paid - this.data.yesterday.paid) / this.data.yesterday.paid) * 100
    }
  }

  get weeklyPaidIncrease() {
    if (!this.data.lastWeek.paid) {
      return (this.data.currentWeek.paid - this.data.lastWeek.paid) * 100
    } else {
      return ((this.data.currentWeek.paid - this.data.lastWeek.paid) / this.data.lastWeek.paid) * 100
    }
  }

  get monthlyPaidIncrease() {
    if (!this.data.lastMonth.paid) {
      return (this.data.currentMonth.paid - this.data.lastMonth.paid) * 100
    } else {
      return ((this.data.currentMonth.paid - this.data.lastMonth.paid) / this.data.lastMonth.paid) * 100
    }
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.fetchDaily()
      this.fetchWeekly()
      this.fetchMonthly()
    }
  }

  fetchDaily() {
    const fetchToday = () => {
      this.state.loading = true

      const [paidQuery] = [1].map(() => {
        return new Model<User>({
          url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
          authorization_token: this.authorizationKey,
          proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
        })
      })

      paidQuery
        .clear()
        .where({
          field: 'status',
          operator: '$eq',
          value: 'PAGO'
        })
        .where({ ...this.filters.today.min as WhereQuery, field: 'criado' })
        .where({ ...this.filters.today.max as WhereQuery, field: 'criado' })
        .run()
        .then((payments: any) => {
          this.data.today.paid = payments.reduce((acc, p) => { return acc + p.valor }, 0)
          this.state.loading = false
        })
    }

    const fetchYesterday = () => {
      this.state.loading = true

      const [paidQuery] = [1].map(() => {
        return new Model<User>({
          url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
          authorization_token: this.authorizationKey,
          proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
        })
      })

      paidQuery
        .clear()
        .where({
          field: 'status',
          operator: '$eq',
          value: 'PAGO'
        })
        .where({ ...this.filters.yesterday.min as WhereQuery, field: 'criado' })
        .where({ ...this.filters.yesterday.max as WhereQuery, field: 'criado' })
        .run()
        .then((payments: any) => {
          this.data.yesterday.paid = payments.reduce((acc, p) => { return acc + p.valor }, 0)
          this.state.loading = false
        })
    }

    fetchToday()
    fetchYesterday()
  }

  fetchWeekly() {
    const fetchCurrentWeek = () => {
      this.state.loading = true

      const [paidQuery] = [1].map(() => {
        return new Model<User>({
          url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
          authorization_token: this.authorizationKey,
          proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
        })
      })

      paidQuery
        .clear()
        .where({
          field: 'status',
          operator: '$eq',
          value: 'PAGO'
        })
        .where({ ...this.filters.currentWeek.min as WhereQuery, field: 'criado' })
        .where({ ...this.filters.currentWeek.max as WhereQuery, field: 'criado' })
        .run()
        .then((payments: any) => {
          this.data.currentWeek.paid = payments.reduce((acc, p) => { return acc + p.valor }, 0)
          this.state.loading = false
        })
    }

    const fetchLastWeek = () => {
      this.state.loading = true

      const [paidQuery] = [1].map(() => {
        return new Model<User>({
          url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
          authorization_token: this.authorizationKey,
          proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
        })
      })

      paidQuery
        .clear()
        .where({
          field: 'status',
          operator: '$eq',
          value: 'PAGO'
        })
        .where({ ...this.filters.lastWeek.min as WhereQuery, field: 'criado' })
        .where({ ...this.filters.lastWeek.max as WhereQuery, field: 'criado' })
        .run()
        .then((payments: any) => {
          this.data.lastWeek.paid = payments.reduce((acc, p) => { return acc + p.valor }, 0)
          this.state.loading = false
        })
    }

    fetchCurrentWeek()
    fetchLastWeek()
  }

  fetchMonthly() {
    const fetchCurrentMonth = () => {
      this.state.loading = true

      const [paidQuery] = [1].map(() => {
        return new Model<User>({
          url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
          authorization_token: this.authorizationKey,
          proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
        })
      })

      paidQuery
        .clear()
        .where({
          field: 'status',
          operator: '$eq',
          value: 'PAGO'
        })
        .where({ ...this.filters.currentMonth.min as WhereQuery, field: 'criado' })
        .where({ ...this.filters.currentMonth.max as WhereQuery, field: 'criado' })
        .run()
        .then((payments: any) => {
          this.data.currentMonth.paid = payments.reduce((acc, p) => { return acc + p.valor }, 0)
          this.state.loading = false
        })
    }

    const fetchPastMonth = () => {
      this.state.loading = true

      const [paidQuery] = [1].map(() => {
        return new Model<User>({
          url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
          authorization_token: this.authorizationKey,
          proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
        })
      })

      paidQuery
        .clear()
        .where({
          field: 'status',
          operator: '$eq',
          value: 'PAGO'
        })
        .where({ ...this.filters.lastMonth.min as WhereQuery, field: 'criado' })
        .where({ ...this.filters.lastMonth.max as WhereQuery, field: 'criado' })
        .run()
        .then((payments: any) => {
          this.data.lastMonth.paid = payments.reduce((acc, p) => { return acc + p.valor }, 0)
          this.state.loading = false
        })
    }

    fetchCurrentMonth()
    fetchPastMonth()
  }
}
