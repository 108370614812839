
















import Vue from 'vue'
import Component from 'vue-class-component'
import InvestmentsTable from '@/components/resume/InvestmentsTable.vue'
import UsersTable from '@/components/resume/UsersTable.vue'

@Component({
  components: {
    UsersTable,
    InvestmentsTable
  }
})
export default class DashboardPage extends Vue {}
