




































































































































import Vue from 'vue'
import Model, { WhereQuery } from '@/lib/prest-query-builder'
import { Component, Watch } from 'vue-property-decorator'
import { User } from '@/interfaces/user'
import UsersChart from '@/components/charts/UsersChart.vue'

const { VUE_APP_API_PREST_NEW, VUE_APP_DASHBOARD_API } = process.env

@Component({
  components: {
    UsersChart
  }
})
export default class InvestmentsTable extends Vue {
  $moment: any

  state = {
    loading: false
  }

  filters = {
    status: {
      field: 'status',
      operator: '$eq',
      value: 'PENDENTE'
    },
    today: {
      min: {
        field: 'data_criacao',
        operator: '$gte',
        value: this.$moment().hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'data_criacao',
        operator: '$lte',
        value: this.$moment().hour(23).minute(59).seconds(59).toISOString()
      }
    },
    yesterday: {
      min: {
        field: 'data_criacao',
        operator: '$gte',
        value: this.$moment().subtract(1, 'days').hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'data_criacao',
        operator: '$lte',
        value: this.$moment().subtract(1, 'days').hour(23).minute(59).seconds(59).toISOString()
      }
    },
    currentWeek: {
      min: {
        field: 'data_criacao',
        operator: '$gte',
        value: this.$moment().weekday(0).hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'data_criacao',
        operator: '$lte',
        value: this.$moment().weekday(6).hour(23).minute(59).seconds(59).toISOString()
      }
    },

    lastWeek: {
      min: {
        field: 'data_criacao',
        operator: '$gte',
        value: this.$moment().weekday(-7).hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'data_criacao',
        operator: '$lte',
        value: this.$moment().weekday(-1).hour(23).minute(59).seconds(59).toISOString()
      }
    },
    currentMonth: {
      min: {
        field: 'data_criacao',
        operator: '$gte',
        value: this.$moment().startOf('month').hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'data_criacao',
        operator: '$lte',
        value: this.$moment().endOf('month').hour(23).minute(59).seconds(59).toISOString()
      }
    },
    lastMonth: {
      min: {
        field: 'data_criacao',
        operator: '$gte',
        value: this.$moment().subtract(1, 'months').startOf('month').hour(0).minute(0).second(0).toISOString()
      },
      max: {
        field: 'data_criacao',
        operator: '$lte',
        value: this.$moment().subtract(1, 'months').endOf('month').hour(23).minute(59).seconds(59).toISOString()
      }
    }
  }

  data = {
    today: 0,
    yesterday: 0,
    currentWeek: 0,
    lastWeek: 0,
    currentMonth: 0,
    lastMonth: 0
  }

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  get authorizationKey() {
    return this.$store.getters['prest/authorizationKey']
  }

  get dailyIncrease() {
    if (!this.data.yesterday) {
      return (this.data.today - this.data.yesterday) * 100
    } else {
      return ((this.data.today - this.data.yesterday) / this.data.yesterday) * 100
    }
  }

  get weeklyIncrease() {
    if (!this.data.lastWeek) {
      return (this.data.currentWeek - this.data.lastWeek) * 100
    } else {
      return ((this.data.currentWeek - this.data.lastWeek) / this.data.lastWeek) * 100
    }
  }

  get monthlyIncrease() {
    if (!this.data.lastMonth) {
      return (this.data.currentMonth - this.data.lastMonth) * 100
    } else {
      return ((this.data.currentMonth - this.data.lastMonth) / this.data.lastMonth) * 100
    }
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.fetchDaily()
      this.fetchWeekly()
      this.fetchMonthly()
    }
  }

  fetchDaily() {
    const fetchToday = () => {
      this.state.loading = true

      const model = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      model
        .clear()
        .where(this.filters.today.min as WhereQuery)
        .where(this.filters.today.max as WhereQuery)
        .run()
        .then((users: any) => {
          this.data.today = users.length
          this.state.loading = false
        })
    }

    const fetchYesterday = () => {
      this.state.loading = true

      const model = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      model
        .clear()
        .where(this.filters.yesterday.min as WhereQuery)
        .where(this.filters.yesterday.max as WhereQuery)
        .run()
        .then((users: any) => {
          this.data.yesterday = users.length
          this.state.loading = false
        })
    }

    fetchToday()
    fetchYesterday()
  }

  fetchWeekly() {
    const fetchCurrentWeek = () => {
      this.state.loading = true

      const model = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      model
        .clear()
        .where(this.filters.currentWeek.min as WhereQuery)
        .where(this.filters.currentWeek.max as WhereQuery)
        .run()
        .then((users: any) => {
          this.data.currentWeek = users.length
          this.state.loading = false
        })
    }

    const fetchLastWeek = () => {
      this.state.loading = true

      const model = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      model
        .clear()
        .where(this.filters.lastWeek.min as WhereQuery)
        .where(this.filters.lastWeek.max as WhereQuery)
        .run()
        .then((users: any) => {
          this.data.lastWeek = users.length
          this.state.loading = false
        })
    }

    fetchCurrentWeek()
    fetchLastWeek()
  }

  fetchMonthly() {
    const fetchCurrentMonth = () => {
      this.state.loading = true

      const model = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      model
        .clear()
        .where(this.filters.currentMonth.min as WhereQuery)
        .where(this.filters.currentMonth.max as WhereQuery)
        .run()
        .then((users: any) => {
          this.data.currentMonth = users.length
          this.state.loading = false
        })
    }

    const fetchPastMonth = () => {
      this.state.loading = true

      const model = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      model
        .clear()
        .where(this.filters.lastMonth.min as WhereQuery)
        .where(this.filters.lastMonth.max as WhereQuery)
        .run()
        .then((users: any) => {
          this.data.lastMonth = users.length
          this.state.loading = false
        })
    }

    fetchCurrentMonth()
    fetchPastMonth()
  }
}
